import * as React from "react"
import Layout from "@/components/Layout"
import Seo from "@/components/seo"
import PageHeading from "@/components/PageHeading"
import AboutUsContainer from "@/components/AboutUsContainer"
import ILine from "@/components/ILine"
import CallToActionMini from "@/components/CallToActionMini"
import PageIntro from "@/components/PageIntro"

import ISection from "@/components/ISection"
import IHeading from "@/components/IHeading"
import IParagraph from "@/components/IParagraph"

const AboutPage = () => (
  <Layout>
    <Seo
      title="Learn more About Us"
      description="Welcome to Easesol, a leading cloud service provider. Our team of experts has years of experience helping businesses of all sizes succeed in the cloud. We offer a range of comprehensive services, including cloud infrastructure and application development, training, and support. Learn more about our team and how we can help your business thrive. Contact us to get started today."
    />
    <div data-aos="fade-up">
      <PageHeading
        title="About Us"
        description="Easesol aims to transform how businesses use the cloud by simplifying the technology and providing an easy path to move into the cloud."
      />
    </div>
    <div className="-mt-10">
      <ILine align="center" />
    </div>

    <section className="w-full my-20">
      <div className="container mx-auto">
        <AboutUsContainer />
      </div>
    </section>

    <section className="w-full my-20">
      <div className="container mx-auto">
        <ISection
          title="Our Mission"
          description="Empowering Businesses through Cloud Computing"
        >
          <IParagraph>
            As a cloud computing service provider, our mission is to help
            businesses of all sizes leverage the power of the cloud to drive
            growth and success. We believe that the cloud has the potential to
            transform industries and improve people's lives, and we are
            committed to using it to make a positive impact.
          </IParagraph>
          <IParagraph>
            To achieve this mission, we offer a range of cloud computing
            services designed to meet the needs of our clients, from cloud
            strategy and architecture design to implementation and ongoing
            support. We are dedicated to delivering innovative solutions and
            top-quality service, and to helping our clients unlock the full
            potential of the cloud.
          </IParagraph>
        </ISection>
      </div>
    </section>

    <CallToActionMini
      title="What we do?"
      description="Learn more about our cloud services and how we can help your business succeed. Contact us for a consultation."
    />
  </Layout>
)

export default AboutPage
